import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Nav, NavTitle, NavLinks, NavContainer } from "./Navbar.styles";

const Navigation = () => {
  const location = useLocation();

  return (
    <>
      <Nav>
        <NavTitle className={location.pathname === "/" ? "active" : ""} to="/">
          Home
        </NavTitle>
        <NavContainer>
          <NavLinks className={location.pathname === "/projects" ? "active" : ""} to="/projects">
            Projects
          </NavLinks>
          <NavLinks className={location.pathname === "/about" ? "active" : ""} to="/about">
            About
          </NavLinks>
          <NavLinks className={location.pathname === "/contact" ? "active" : ""} to="/contact">
            Contact
          </NavLinks>
        </NavContainer>
      </Nav>
      <Outlet />
    </>
  );
};
export default Navigation;
