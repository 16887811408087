import React from "react";
import {
  Foot,
  FootIcon,
  FootText,
  IconContainer,
  SubFootText,
  SmallFootIcon,
} from "./Footer.styles";
import { FaInstagram, FaLinkedinIn, FaGithub } from "react-icons/fa";
import { SiResearchgate } from "react-icons/si";
import { Outlet } from "react-router-dom";

const Footer = () => {
  const date = new Date();

  const year = date.getFullYear();

  return (
    <>
      <Foot>
        <IconContainer>
          <FootIcon target="_blank" href="https://github.com/Jdivito97">
            <FaGithub />
          </FootIcon>
          <FootIcon
            target="_blank"
            href="https://www.linkedin.com/in/jonathan-divito-bb0a7a1ba/"
          >
            <FaLinkedinIn />
          </FootIcon>
          <FootIcon
            target="_blank"
            href="https://www.researchgate.net/profile/Jonathan-Divito-2"
          >
            <SiResearchgate />
          </FootIcon>
        </IconContainer>
        <FootText>© Jonathan DiVito {year}</FootText>
        <SubFootText>
          <SmallFootIcon
            target="_blank"
            href="https://www.instagram.com/maggiestoneham/"
          >
            {" "}
            <FaInstagram />{" "}
          </SmallFootIcon>{" "}
          All Photographs © Margaret Stoneham {year}{" "}
        </SubFootText>
      </Foot>
      <Outlet />
    </>
  );
};

export default Footer;
