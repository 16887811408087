import React from "react";
import resume from "../../assets/pdf/Jonathan_DiVito_Resume_2024.pdf";
import {
  AboutBody,
  AboutTextContainer,
  AboutTitle,
  AboutDescriptions,
  AltContainer,
  Tldr,
  TldrTitle,
} from "./About.styles";
import {
  PrimaryContainer,
  PrimarySection,
  Title,
  SecondarySection,
  SecondaryTitle,
  SecondaryContainer,
  ResumeButton,
} from "../../components/ReusableComponents/ReuasableComponents";

const AboutPage = () => {
  return (
    <>
      <AboutBody>
        <PrimarySection>
          <PrimaryContainer>
            <Title> About me</Title>
            <ResumeButton
              target='_blank'
              variant='extended'
              color='primary'
              href={resume}
            >
              View Resume
            </ResumeButton>
          </PrimaryContainer>
        </PrimarySection>
      </AboutBody>
      <SecondarySection>
        <SecondaryContainer>
          <SecondaryTitle> A bit of context</SecondaryTitle>
        </SecondaryContainer>

        <AboutTextContainer>
          <AboutTitle> My Background </AboutTitle>
          <AboutDescriptions>
            I was born and raised in Chicago, Illinois. In 2015, I moved to San
            Marcos, Texas to attend Texas State University. I graduated in 2019
            with a B.Sc. in Wildlife Biology. I currently enjoy being immersed
            the quiet serenity of the Texas Hill Country with every passing day.
          </AboutDescriptions>
        </AboutTextContainer>
        <AltContainer>
          <AboutDescriptions>
            I received a Bachelor’s of Science degree in Wildlife Biology in
            2019 from Texas State university. Through this program I learned how
            to incorporate the scientific method into every day decision making,
            natural history and identification of America's flora and fauna,
            wildlife management techniques and strategies, scientific writing,
            and how to conduct ethical and worthwhile research.
            <br />
            <br />
            After two years of working in the field of biological consulting, I
            had a desire to learn what is sorely lacking in the world of
            biological sciences, software development.
            <br />
            <br />
            At the end of 2021, I signed up for Complete 2022 Web Development
            Bootcamp. Through this bootcamp I acquired the skills and knowledge
            to use ReactJS, Javascript, Express, MySQL, MongoDB, JQuery,
            Node.js, RESTful API's, HTML5, and CSS3. Of these technologies
            ReactJS stood out to me and was my favorite framework to work with.
            After completing my first bootcamp, I signed up for a React specific
            bootcamp in the beginning of 2022. Through The Complete React
            Developer in 2022 Bootcamp I acquired the skills needed to build
            fully fledged production level sites using React and its tools
            contained within. Because of my desire to continue to learn, I am
            consistently enrolling in online courses to further expand my
            knowledge base.
            <br />
            <br />I am presently employed as a fulltime Web Developer at Impulse
            Creative, a Hubspot Elite Partner specializing in custom software
            solutions within the Hubspot CRM.
            <TldrTitle>TL-DR</TldrTitle>
            <Tldr>
              B.Sc. Wildlife Biology from Texas State University completed
              December 2019.
            </Tldr>
            <Tldr>
              Complete 2022 Web Development Bootcamp completed February 2022
            </Tldr>
            <Tldr>
              Complete React Developer in 2022 Bootcamp completed May 2022
            </Tldr>
            <Tldr>
              Complete React Native in 2023: Zero to Mastery Bootcamp completed
              October 2022
            </Tldr>
            <Tldr>
              Git Complete: The definitive, step-by-step guide to Git Bootcamp
              completed March 2023
            </Tldr>
          </AboutDescriptions>
          <AboutTitle> Education </AboutTitle>
        </AltContainer>
        <AboutTextContainer>
          <AboutTitle> Career History & Objectives </AboutTitle>
          <AboutDescriptions>
            After receiving my B.Sc. in December of 2019 I spent two years
            working as a biologist for a biological consulting firm. There, I
            worked on a multitude of projects such as monitoring Pipeline
            environmental compliance and an extensive life history study on the
            Brazos Watersnake (<em>Nerodia harteri harteri</em>). When out of
            the field, my duties included identifying specimens in the lab,
            using software programs such as Excel and R to run statistical
            analyses on datasets, and co-authoring scientific papers on our
            findings.
            <br />
            <br />
            Although I enjoyed the challenging yet fun projects, the extensive
            travel and feast or famine nature of the workload were unsustainable
            for my long-term goals. Thus I began my journey into software
            development for a more sustainable long-term career path.
            <br />
            <br />
            Since learning how to code, I have been working as a part-time web
            developer for a small consulting company out of Los Angeles called
            VOXSA consulting, as well as a small startup in Los Angeles called
            4th-floor (4th-floor.com) where I am working on building a react app
            to be to nested inside of a Virtual Reality space. I have also
            worked as a freelance React developer for marketlounge.org where I
            assisted in building reusable components, implementing API calls,
            debugging, and styling input and execution.
            <br />
            <br />I am currently seeking a full-time remote React based frontend
            position that will challenge and evolve my skillset. My strongest
            desire for my next position is that I will be consistently
            challenged with high level tasks while simultaneously learning new
            techniques and best practices.
            <TldrTitle>TL-DR</TldrTitle>
            <Tldr>
              Worked as a Biologist for 2 years after recieving my degree
            </Tldr>
            <Tldr>Decided software development was a wiser career path</Tldr>
            <Tldr>
              Currently employed as a Fullstack Developer for Impulse Creative
            </Tldr>
          </AboutDescriptions>
        </AboutTextContainer>
        <AltContainer>
          <AboutDescriptions>
            During my free time, I like to keep myself active and stimulated. I
            have many hobbies including hiking, camping, weight lifting,
            snorkeling, fishing, playing hockey, and taking my dog to local
            rivers and lakes. I also enjoy 'herping', which is a niche hobby
            where I try to find reptiles and amphibians in places they have yet
            to be documented in order to publish the findings.
          </AboutDescriptions>
          <AboutTitle> Personal Interests & Hobbies </AboutTitle>
        </AltContainer>
      </SecondarySection>
    </>
  );
};

export default AboutPage;
