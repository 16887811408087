import styled from "@emotion/styled";
import img from "../../assets/Imgs/jon4.webp";

export const AboutBody = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  margin: 0;
  background-image: url(${img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media screen and (max-width: 550px) {
    background-position: 20%;
  }
`;
export const AboutTextContainer = styled.div`
  display: flex;
  height: fit-content;
  width: 80%;
  margin: 100px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  overflow-y: hidden;
  overflow-x: hidden;
  @media screen and (max-width: 975px) {
    flex-direction: column;
    margin: 50px;
  }
  @media screen and (max-width: 750px) {
  }
`;

export const AboutTitle = styled.h1`
  text-align: center;
  font-size: 2.9rem;
  text-align: center;
  margin: 0 50px 0px 50px;
  font-weight: 900;
  color: #ecb365;
  @media screen and (max-width: 975px) {
    font-size: 2rem;
    margin-bottom: 20px;
  }
`;

export const AltContainer = styled(AboutTextContainer)`
  @media screen and (max-width: 975px) {
    flex-direction: column-reverse;
  }
`;

export const AboutDescriptions = styled.h4`
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
  margin-top: 0%;
  font-size: 1.3rem;
  margin: 0 50px 0px 50px;
  color: #041c32;
  @media screen and (max-width: 750px) {
    margin: 50px 0 0 0;
    font-size: 1.1rem;
  }
`;

export const TldrContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
`;

export const TldrTitle = styled.p`
  text-decoration: underline;
  font-weight: 700;
  text-align: left;
  font-size: 1.8rem;
  color: #041c32;
`;

export const Tldr = styled.li`
  font-weight: 700;
  line-height: 1.9;
  text-align: left;
  font-size: 0.95rem;
  color: #041c32;
`;
