import styled from "@emotion/styled";
import img from "../../assets/Imgs/IMG_4412.webp";

import {
  PrimaryContainer,
  Title,
  Subtitle,
  SecondaryTitle,
  SecondarySubtitle,
  PrimarySection,
  SecondarySection,
} from "../../components/ReusableComponents/ReuasableComponents";

export const ProjectsBody = styled.div`
  height: 100%;
  width: 100%;
  margin: 0;
  background-image: url(${img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 5%;
  @media screen and (max-width: 800px) {
    background-position: 70%;
  }
`;
