import React from "react";
import { ProjectsBody } from "./Projects.styles";
import {
  PrimaryContainer,
  Title,
  Subtitle,
  SecondaryTitle,
  SecondarySubtitle,
  PrimarySection,
  SecondarySection,
  SecondaryContainer,
  ProjectCard,
  ContributionsCard,
  PublicationsCard,
} from "../../components/ReusableComponents/ReuasableComponents";

const ProjectsPage = () => {
  return (
    <>
      <ProjectsBody>
        <PrimarySection>
          <PrimaryContainer>
            <Title>Projects archive</Title>
          </PrimaryContainer>
        </PrimarySection>
      </ProjectsBody>{" "}
      <SecondarySection>
        <SecondaryContainer>
          <SecondaryTitle>Some of my projects</SecondaryTitle>
        </SecondaryContainer>

        <ProjectCard />

        <SecondaryContainer>
          <SecondaryTitle>Projects I've contributed to</SecondaryTitle>
        </SecondaryContainer>
        <ContributionsCard />
        <SecondaryContainer>
          <SecondaryTitle>Scientific publications</SecondaryTitle>
        </SecondaryContainer>
        <PublicationsCard />
      </SecondarySection>
    </>
  );
};

export default ProjectsPage;
