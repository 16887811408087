import styled from "@emotion/styled";
import { FormControl, FormGroup, Input } from "@mui/material";
import img from "../../assets/Imgs/7D537F57-05E6-4DEE-AE28-E69863FE9258.webp";
import { GeneralButton } from "../../components/ReusableComponents/ReuasableComponents";

export const ContactBody = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  margin: 0;
  background-image: url(${img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const ContactFormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 50%;
  @media screen and (max-width: 650px) {
    width: 90%;
  }
`;

export const EmailForm = styled.form`
  justify-content: center;
`;

export const InputField = styled(Input)`
  width: 100%;
  padding: 10px;
`;
export const MessageField = styled(InputField)`
  min-height: 200px;
  max-height: min-content;
  margin-bottom: 20px;
  flex-wrap: wrap;
  overflow-y: auto;
`;
export const SendButton = styled(GeneralButton)`
  width: 100%;
`;
