import React, { useState, useRef } from "react";
import {
  ContactBody,
  ContactFormContainer,
  EmailForm,
  InputField,
  SendButton,
  MessageField,
} from "./Contact.styles";
import {
  PrimaryContainer,
  PrimarySection,
  Title,
  Subtitle,
  SecondarySection,
  SecondaryTitle,
  SecondarySubtitle,
  SecondaryContainer,
} from "../../components/ReusableComponents/ReuasableComponents";
import { send } from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";

const ContactPage = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [buttonText, setButtonText] = useState("Send Email");
  const [toSend, setToSend] = useState({
    from_name: "",
    to_name: "Jonathan",
    message: "",
    reply_to: "",
    "g-recaptcha-response": "",
  });
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);
  const recaptchaRef = useRef(null);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!isRecaptchaVerified) return;
    setIsSubmitted(true);
    setButtonText("sending...");
    send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      toSend,
      process.env.REACT_APP_EMAILJS_USER_ID
    )
      .then((response) => {
        setButtonText("Thank you, your email has been sent.");
      })
      .catch((err) => {
        alert("email not sent, please try again");
      });
  };

  const onRecaptchaChange = (token) => {
    setToSend({ ...toSend, "g-recaptcha-response": token });
    setIsRecaptchaVerified(true);
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };
  return (
    <>
      <ContactBody>
        <PrimarySection>
          <PrimaryContainer>
            <Title>Contact me</Title>
          </PrimaryContainer>
        </PrimarySection>
      </ContactBody>
      <SecondarySection>
        <SecondaryContainer>
          <SecondaryTitle>
            If you would like to get in touch with me send me an email below,
            <br />
            or send me a message on Linkedin
          </SecondaryTitle>
        </SecondaryContainer>

        <ContactFormContainer>
          <EmailForm onSubmit={onSubmit} class="mui-form">
            <InputField
              type="text"
              name="from_name"
              inputProps={{ maxLength: 45 }}
              placeholder="Your name"
              value={toSend.from_name}
              onChange={handleChange}
            />
            <InputField
              type="text"
              inputProps={{ maxLength: 45 }}
              name="reply_to"
              placeholder="Your email"
              value={toSend.reply_to}
              onChange={handleChange}
            />
            <MessageField
              type="text"
              multiline={true}
              name="message"
              placeholder="Your message"
              value={toSend.message}
              onChange={handleChange}
            />
            <ReCAPTCHA
              sitekey="6LctvJYlAAAAAM-fVQKwPsyUUQhOZjXdDI3dXOsd"
              ref={recaptchaRef}
              onChange={onRecaptchaChange}
            />
            <SendButton
              variant="extended"
              color="primary"
              type="submit"
              disabled={isSubmitted || !isRecaptchaVerified}
            >
              {buttonText}
            </SendButton>{" "}
          </EmailForm>
        </ContactFormContainer>
      </SecondarySection>
    </>
  );
};

export default ContactPage;
