import styled from "@emotion/styled";
import {
  PrimarySection,
  SecondarySection,
} from "../../components/ReusableComponents/ReuasableComponents";
import img from "../../assets/Imgs/IMG_4457.webp";
import { Button, Fab, Icon } from "@mui/material";

export const HomeBody = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  margin: 0;
  background-image: url(${img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const IconGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const LargeIcon = styled.div`
  font-size: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-content: space-between;
  padding: 10px;
  & :hover {
    transform: scale(1.1);
    transition: transform 4s cubic-bezier(0.25, 0.45, 0.45, 0.95);
    overflow: visible;
  }
  @media screen and (max-width: 450px) {
    font-size: 40px;
  }
`;
export const MediumIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 40px;
  padding: 10px;
  & :hover {
    transform: scale(1.1);
    transition: transform 4s cubic-bezier(0.25, 0.45, 0.45, 0.95);
    overflow: visible;
  }
  @media screen and (max-width: 450px) {
    font-size: 30px;
  }
`;
export const SmallIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 30px;
  padding: 10px;

  & :hover {
    transform: scale(1.1);
    transition: transform 4s cubic-bezier(0.25, 0.45, 0.45, 0.95);
    overflow: visible;
  }
  @media screen and (max-width: 450px) {
    font-size: 35px;
  }
`;

export const ProjectsButton = styled(Fab)`
  font-family: "Jura", sans-serif;
  color: #ecb365;
  background-color: #064663;
  background-image: url("https://www.transparenttextures.com/patterns/absurdity.png");
  & :hover {
    background-color: #041c32 !important;
  }
`;
