import "./App.css";
import { Route, Routes } from "react-router-dom";
import Navigation from "./components/Navbar/Navbar";
import HomePage from "./routes/Home/Home";
import ProjectsPage from "./routes/Projects/Projects";
import AboutPage from "./routes/About/About";
import ContactPage from "./routes/Contact/Contact";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigation />}>
          <Route index element={<HomePage />} />
          <Route path="projects" element={<ProjectsPage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="contact" element={<ContactPage />} />
        </Route>
      </Routes>
      <Footer />
    </>
  );
}

export default App;
