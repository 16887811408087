import styled from "@emotion/styled";
import { IconButton } from "@mui/material";

export const Foot = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f9f3ee;
`;

export const FootText = styled.h4`
  text-align: center;
  font-size: 1.2rem;
  margin: 10px;
  top: 60%;
  color: #064663;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

export const FootIcon = styled(IconButton)`
  border-radius: 50%;
  color: #064663;
  margin: 0 15px 0 15px;
  padding: 5px;
  & :hover {
    transform: scale(1.1);
    transition: transform 2s cubic-bezier(0.25, 0.45, 0.45, 0.5);
    overflow: visible;
  }
`;

export const SmallFootIcon = styled(FootIcon)`
  font-size: medium;
  margin: 0;
  & :hover {
    transform: scale(1.1);
    transition: transform 2s cubic-bezier(0.5, 0.5, 0.5, 0.5);
    overflow: visible;
  }
`;

export const SubFootText = styled(FootText)`
  margin: 0;
  font-size: xx-small;
`;
