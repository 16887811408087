import styled from "@emotion/styled/macro";
import { Link } from "react-router-dom";

export const Nav = styled.nav`
  height: 50px;
  width: 100%;
  position: absolute;
  padding: 5px;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width: 500px) {
    justify-content: center;
  }
`;

export const NavTitle = styled(Link)`
  font-size: 1.5rem;
  text-decoration: none;
  padding: 10px;
  height: 32.5px;
  margin: 0 10px 5px;
  background-color: #064663;
  color: #ecb365;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  letter-spacing: 4px;
  overflow: hidden;
  transition: 0.5s;
  cursor: pointer;
  &:hover {
    background: #064663;
    border: 0px;
    color: #ecb365;
    box-shadow: 0 0 5px #ecb365, 0 0 25px #ecb365, 0 0 50px #ecb365, 0 0 200px #ecb365;
    -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
  }
  &.active {
    box-shadow: 0 0 5px #ecb365, 0 0 25px #ecb365, 0 0 50px #ecb365, 0 0 200px #ecb365;
  }
`;

export const NavLinks = styled(Link)`
  text-decoration: none;
  padding: 10px;
  height: 20px;
  margin: 0 2px;
  background-color: #064663;
  color: #ecb365;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  letter-spacing: 4px;
  overflow: hidden;
  transition: 0.5s;
  cursor: pointer;
  &:hover {
    background: #064663;
    border: 0px;
    color: #ecb365;
    box-shadow: 0 0 5px #ecb365, 0 0 25px #ecb365, 0 0 50px #ecb365, 0 0 200px #ecb365;
    -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
  }
  &.active {
    box-shadow: 0 0 5px #ecb365, 0 0 25px #ecb365, 0 0 50px #ecb365, 0 0 200px #ecb365;
  }
`;

export const NavContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  margin: 0 25px;
`;
