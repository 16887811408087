import styled from "@emotion/styled";
import { IconButton, Fab } from "@mui/material";
import { FaGithub, FaLink } from "react-icons/fa";
import { SiResearchgate } from "react-icons/si";
import { Contributions, MainProject, OtherProjects, Publications } from "../../data";

export const PrimarySection = styled.div`
  height: 800px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0;
`;
export const SecondarySection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-height: 800px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  overflow: visible;
  background-color: #f9f3ee;
`;
export const PrimaryContainer = styled.div`
  height: 100%;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0%;
`;
export const SecondaryContainer = styled.div`
  height: fit-content;
  width: 100vw;
  display: flex;
  padding: 3px;
  flex-wrap: wrap;
  justify-content: center;
  justify-content: space-around;
`;

export const Title = styled.h1`
  text-align: center;
  position: relative;
  bottom: 20%;
  font-size: 3.5rem;
  font-weight: 900;
  color: #ecb365;
  text-shadow: #041c32 1px 1px 1px;

  text-align: center;
`;
export const Subtitle = styled.h3`
  height: 30px;
  font-size: 1.5rem;
  outline: #ecb365;
  outline-width: 5px;
  outline-color: #ecb365;
  outline-style: hidden;
  padding: 5px;
  border-radius: 8px;
  background-color: #ecb365;
  text-align: center;
  color: #041c32;
  text-shadow: #ecb365 1px 1px 1px;
`;

export const SecondaryTitle = styled.h1`
  font-size: 2rem;
  margin: 20px;
  padding: 7px;
  text-align: center;
  font-weight: 900;
  color: #ecb365;
`;

export const SecondarySubtitle = styled.p`
  margin-top: 0%;
  font-size: 1rem;
  text-decoration: overline;
  color: #041c32;
`;

export const Card = styled.div`
  display: flex;
  margin: 15px;
  padding: 0;
  flex-direction: column;
  justify-content: center;
  min-height: 200px;
  max-height: fit-content;
  width: 35%;
  background-color: #064663;
  border-radius: 8px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  background-image: url("https://www.transparenttextures.com/patterns/absurdity.png");
  @media screen and (max-width: 975px) {
    max-height: 150px;
  }
  @media screen and (max-width: 730px) {
    width: 270px;
  }
`;
export const CardHeader = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: fit-content;
  background-color: #04293a;
  width: 100%;
  border-radius: 8px 8px 0px 0;
`;

export const CardTitle = styled.h3`
  font-size: 1.5rem;
  margin: 0;
  padding: 5px;
  font-weight: 200;
  text-align: start;
  color: #ecb365;
  @media screen and (max-width: 1317px) {
    font-size: 1rem;
  }
`;

export const CardIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;
export const CardIcon = styled(IconButton)`
  border-radius: 50%;
  color: #ecb365;
  margin: 0 15px 0 15px;

  padding: 5px;
  & :hover {
    transform: scale(1.1);
    transition: transform 2s cubic-bezier(0.25, 0.45, 0.45, 0.5);
    overflow: visible;
  }
  @media screen and (max-width: 730px) {
    margin: 0;
  }
`;

export const CardBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  margin: 10px;
  width: fit-content;
  height: 100%;
`;

export const CardDescription = styled.h3`
  font-weight: 600;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: 5px;
  text-align: center;
  justify-content: center;
  color: #ecb365;
  @media screen and (max-width: 1160px) {
    top: 0%;
  }
  @media screen and (max-width: 1000px) {
    top: 0%;
    font-size: 75%;
  }
`;

export const CardSubDescription = styled.h6`
  font-weight: 100;
  position: relative;
  margin: 10px;
  padding: 20px;
  text-align: center;
  justify-content: center;
  color: #ecb365;

  @media screen and (max-width: 900px) {
    margin-top: 0;
    top: 0%;
  }
`;

export const CardPhotoContainer = styled.div`
  display: flex;
  padding: 5px;
  @media screen and (max-width: 1100px) {
    display: none;
  }
`;

export const CardTextContainer = styled.div`
  display: flex;
  width: 60%;
  justify-content: center;
  flex-wrap: wrap;
`;

export const CardPhoto = styled.img`
  border-radius: 8px;
  margin: 0px;
  padding: 0px;
  height: 100%;
  width: 100%;
`;

export const PublicationCard = styled(Card)`
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 30%;
  @media screen and (max-width: 980px) {
    width: 270px;
    height: 200px;
  }
`;

export const PublicationTextContainer = styled(CardTextContainer)`
  width: 100%;
  margin: 0;
  padding: 0;
`;

export const JournalTitle = styled(CardTitle)`
  font-size: 1rem;
  flex-wrap: wrap;
  font-weight: 100;
  @media screen and (max-width: 1190px) {
    font-size: 0.75rem;
  }
  @media screen and (max-width: 980px) {
    display: none;
  }
`;
export const PublicationTitle = styled(CardDescription)`
  font-size: 1.5rem;
  margin: 0;
  font-weight: 700;

  @media screen and (max-width: 1000px) {
    font-size: 1rem;
  }
  @media screen and (max-width: 980px) {
    font-size: 1rem;
  }
`;

export const GeneralButton = styled(Fab)`
  font-family: "Jura", sans-serif;
  border-radius: 8px;
  color: #ecb365;
  background-color: #064663;
  background-image: url("https://www.transparenttextures.com/patterns/absurdity.png");

  &:hover {
    background: #064663;
    border: 0px;
    color: #ecb365;
    box-shadow: 0 0 5px #ecb365, 0 0 25px #ecb365, 0 0 50px #ecb365, 0 0 200px #ecb365;
    -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
  }
`;
export const Authors = styled(CardSubDescription)`
  @media screen and (max-width: 980px) {
    display: none;
  }
`;

export const ResumeButton = styled(GeneralButton)`
  width: 150px;
`;

export const FeaturedProjectCard = () => {
  return (
    <>
      {MainProject.map((obj) => (
        <Card key={obj.id}>
          <CardHeader>
            <CardTitle>{obj.title}</CardTitle>

            <CardIconContainer>
              <CardIcon target="_blank" href={obj.link}>
                <FaLink />
              </CardIcon>
              <CardIcon target="_blank" href={obj.gitlink}>
                <FaGithub />
              </CardIcon>
            </CardIconContainer>
          </CardHeader>
          <CardBody>
            <CardPhotoContainer>
              <CardPhoto src={obj.siteImg} alt="website screenshot" />
            </CardPhotoContainer>
            <CardTextContainer>
              <CardDescription>{obj.about}</CardDescription>
              <CardSubDescription>{obj.details}</CardSubDescription>
            </CardTextContainer>
          </CardBody>
        </Card>
      ))}
    </>
  );
};

export const ProjectCard = () => {
  return (
    <>
      {OtherProjects.map((obj) => (
        <Card key={obj.id}>
          <CardHeader>
            <CardTitle>{obj.title}</CardTitle>

            <CardIconContainer>
              {obj.link && (
                <CardIcon target="_blank" href={obj.link}>
                  <FaLink />
                </CardIcon>
              )}

              {obj.gitlink && (
                <CardIcon target="_blank" href={obj.gitlink}>
                  <FaGithub />
                </CardIcon>
              )}
            </CardIconContainer>
          </CardHeader>
          <CardBody>
            <CardPhotoContainer>
              <CardPhoto src={obj.siteImg} alt="website screenshot" />
            </CardPhotoContainer>
            <CardTextContainer>
              <CardDescription>{obj.about}</CardDescription>
              <CardSubDescription>{obj.details}</CardSubDescription>
            </CardTextContainer>
          </CardBody>
        </Card>
      ))}
    </>
  );
};

export const ContributionsCard = () => {
  return (
    <>
      {Contributions.map((obj) => (
        <Card key={obj.id}>
          <CardHeader>
            <CardTitle>{obj.title}</CardTitle>

            <CardIconContainer>
              {obj.link && (
                <CardIcon target="_blank" href={obj.link}>
                  <FaLink />
                </CardIcon>
              )}
            </CardIconContainer>
          </CardHeader>
          <CardBody>
            <CardPhotoContainer>
              <CardPhoto src={obj.siteImg} alt="website screenshot" />
            </CardPhotoContainer>
            <CardTextContainer>
              <CardDescription>{obj.about}</CardDescription>
              <CardSubDescription>{obj.details}</CardSubDescription>
            </CardTextContainer>
          </CardBody>
        </Card>
      ))}
    </>
  );
};

export const PublicationsCard = () => {
  return (
    <>
      {Publications.map((obj) => (
        <PublicationCard key={obj.id}>
          <CardHeader>
            <JournalTitle>{obj.title}</JournalTitle>

            <CardIconContainer>
              <CardIcon target="_blank" href={obj.link}>
                <FaLink />
              </CardIcon>
            </CardIconContainer>
          </CardHeader>
          <CardBody>
            <PublicationTextContainer>
              <PublicationTitle>{obj.about}</PublicationTitle>
              <Authors>{obj.details}</Authors>
            </PublicationTextContainer>
          </CardBody>
        </PublicationCard>
      ))}
    </>
  );
};
