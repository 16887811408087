import img1 from "./assets/Imgs/Bird-alertCapture.JPG";
import img2 from "./assets/Imgs/threadMonkeyCapture.JPG";
import img3 from "./assets/Imgs/SimpleSkyCapture.JPG";
import img4 from "./assets/Imgs/SmacCapture.JPG";
import img5 from "./assets/Imgs/RoboCapture.JPG";
import img6 from "./assets/Imgs/MuttCapture.JPG";
import img7 from "./assets/Imgs/marketCapture.JPG";
import img8 from "./assets/Imgs/Screenshot 2023-01-27 163756.png";

export const MainProject = [
  {
    id: 11,
    title: "Bird-Alert",
    link: "https://bird-alert.org/",
    gitlink: "https://github.com/Jdivito97/Bird-Alert",
    siteImg: `${img1}`,
    // prettier-ignore
    about: "A React SPA for displaying recently observed birds in a selected area.",
    details: "React, Axios, REST, Node.js",
  },
];

export const OtherProjects = [
  {
    id: 1,
    title: "Bird-Alert",
    link: "https://bird-alert.org/",
    gitlink: "https://github.com/Jdivito97/Bird-Alert",
    siteImg: `${img1}`,
    // prettier-ignore
    about: "A React SPA for displaying recently observed birds in a selected area",
    details: "React, Axios, REST, Node.js",
  },

  {
    id: 12,
    title: "Knox Swan & Dog",
    link: "https://knox-swan-dog.vercel.app/",

    siteImg: `${img8}`,
    // prettier-ignore
    about: "A React SPA custom built for a goose control company in the Chicagoland area",
    details: "React, Material UI, emailjs",
  },

  {
    id: 2,
    title: "ThreadMonkey",
    link: "https://moonlit-taiyaki-565592.netlify.app/",
    gitlink: "https://github.com/Jdivito97/threadmonkey",
    siteImg: `${img2}`,
    // prettier-ignore
    about: "Fully fledged mock ecomerce site",
    details: "React, Context API, Styled Components, Firebase, Stripe",
  },
  {
    id: 3,
    title: "SimpleSky",
    gitlink: "https://github.com/Jdivito97/SimpleSky",
    siteImg: `${img3}`,
    // prettier-ignore
    about: "React weather app using Openweather API and Axios",
    details: "React, REST, Axios, CSS",
  },
  {
    id: 4,
    title: "SMAC",
    link: "https://smac-site.vercel.app/",
    gitlink: "https://github.com/Jdivito97/SMAC_SITE",
    siteImg: `${img4}`,
    // prettier-ignore
    about: "Website re-design for an oldschool iron gym",
    details: "React, MaterialUI, Google Maps API ",
  },
  {
    id: 5,
    title: "Robots Rolodex",
    gitlink: "https://github.com/Jdivito97/Robo-rolodex",
    siteImg: `${img5}`,
    // prettier-ignore
    about: "A React app demonstrating my ability to create a reuasable rolodex component",
    details: "React, SASS, Axios",
  },
  {
    id: 6,
    title: "Muttmates",
    link: "https://jdivito97.github.io/muttmates/",
    gitlink: "https://github.com/Jdivito97/muttmates",
    siteImg: `${img6}`,
    // prettier-ignore
    about: "A very silly mock website for a dog dating app to display bootstrap styling skills ",
    details: "Bootstrap",
  },
];

export const Contributions = [
  {
    id: 7,
    title: "Market Lounge",
    link: "https://marketlounge.org/",
    siteImg: `${img7}`,
    // prettier-ignore
    about: "A React app for seeing the current market data and most recent market news",
    details: "Tasked with debugging, styling, and built reusable components",
  },
];

export const Publications = [
  {
    id: 8,
    title: "Distribution of Herpetofauna in Texas",
    link: "https://www.researchgate.net/publication/359633849_Filling_in_the_Gaps_50_Novel_Distribution_Records_for_Herpetofauna_of_the_State_of_Texas_USA",
    // prettier-ignore
    about: "Filling in the Gaps: 50 Novel Distribution Records for Herpetofauna of the State of Texas, USA",
    details:
      "Gregory Pandelis, Lawrence Grant Basset, Dalton B. Lawing, Ivory N. Lowe, Jonathan DiVito, Sarah Bullard, Nicholas S. Hughes, Michael J. R. Forstner ",
  },
  {
    id: 9,
    title: "The Pan-Pacific Entomologist",
    link: "https://www.researchgate.net/publication/359631172_Centruroides_vittatus_Say_1821_Scorpiones_Buthidae_as_a_scavenger_of_a_recently_killed_Dekay's_brown_snake_Storeria_dekayi_Holbrook_1839_Squamata_Colubridae_carcass",
    // prettier-ignore
    about: "Centruroides vittatus (Say, 1821) (Scorpiones: Buthidae) as a scavenger of a recently killed Dekay′s brown snake, Storeria dekayi (Holbrook, 1839) (Squamata: Colubridae), carcass",
    details: "Lawrence Grant Basset, Jonathan DiVito, Michael J. R. Forstner",
  },
  {
    id: 10,
    title: "Herpetological Review",
    link: "https://www.researchgate.net/publication/359633849_Filling_in_the_Gaps_50_Novel_Distribution_Records_for_Herpetofauna_of_the_State_of_Texas_USA",
    // prettier-ignore
    about: "Geographic Distribution: USA, Texas, Nolan Co.: Hemidactylus turcicus (Mediterranean Gecko)",
    details:
      "Jonathan DiVito, Margaret Stoneham, Lawrence Grant Basset, Ferris Eric-Hisham Zughaiyir, Michael J. R. Forstner",
  },
];
