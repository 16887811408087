import React from "react";

import resume from "../../assets/pdf/Jonathan_DiVito_Resume_2024.pdf";
import { HomeBody, LargeIcon, MediumIcon, SmallIcon } from "./Home.styles";
import {
  Title,
  SecondaryTitle,
  Subtitle,
  PrimaryContainer,
  PrimarySection,
  SecondarySection,
  SecondaryContainer,
  FeaturedProjectCard,
  GeneralButton,
  ResumeButton,
} from "../../components/ReusableComponents/ReuasableComponents";
import {
  FaHtml5,
  FaCss3Alt,
  FaReact,
  FaSass,
  FaNode,
  FaFigma,
  FaGithub,
} from "react-icons/fa";
import {
  SiJavascript,
  SiNextdotjs,
  SiTypescript,
  SiPostgresql,
  SiFirebase,
  SiMaterialui,
  SiStyledcomponents,
  SiJquery,
  SiJirasoftware,
  SiAndroidstudio,
  SiVisualstudiocode,
} from "react-icons/si";

const HomePage = () => {
  return (
    <>
      <HomeBody>
        <PrimarySection>
          <PrimaryContainer>
            <Title>Jonathan DiVito</Title>

            <Subtitle>Front End Engineer | Biologist </Subtitle>

            <ResumeButton
              target='_blank'
              variant='extended'
              color='primary'
              href={resume}
            >
              View Resume
            </ResumeButton>
          </PrimaryContainer>
        </PrimarySection>
      </HomeBody>
      <SecondarySection>
        <SecondaryContainer>
          <SecondaryTitle>Technology I work with</SecondaryTitle>
          <SecondaryContainer>
            <LargeIcon>
              <FaReact style={{ color: "deepskyblue", margin: 5 }} />
              <SiJavascript style={{ color: "gold", margin: 5 }} />
              <FaHtml5 style={{ color: "red", margin: 5 }} />
              <FaCss3Alt style={{ color: "darkblue", margin: 5 }} />
              <SiMaterialui style={{ color: "cadetblue", margin: 5 }} />
              <FaGithub style={{ color: "darkorchid", margin: 5 }} />
              <SiVisualstudiocode
                style={{ color: "slateblue", margin: 5 }}
              />{" "}
            </LargeIcon>
          </SecondaryContainer>
          <SecondaryContainer>
            <MediumIcon>
              <SiTypescript style={{ color: "darkcyan", margin: 5 }} />

              <FaFigma style={{ color: "mediumvioletred", margin: 5 }} />
              <SiFirebase style={{ color: "orange", margin: 5 }} />
              <FaSass style={{ color: "violet", margin: 5 }} />
              <SiAndroidstudio style={{ color: "green", margin: 5 }} />
              <SiStyledcomponents style={{ color: "tomato", margin: 5 }} />
            </MediumIcon>
          </SecondaryContainer>
          <SecondaryContainer>
            <SmallIcon>
              <SiJirasoftware style={{ color: "dodgerblue", margin: 5 }} />
              <SiJquery style={{ color: "coral", margin: 5 }} />
              <SiPostgresql style={{ color: "cornflowerblue", margin: 5 }} />
              <SiNextdotjs style={{ color: "deeppink", margin: 5 }} />
              <FaNode style={{ color: "crimson", margin: 5 }} />
            </SmallIcon>
          </SecondaryContainer>
        </SecondaryContainer>
        <SecondaryContainer>
          <SecondaryTitle>Featured Project</SecondaryTitle>
          <SecondaryContainer>
            <FeaturedProjectCard />
          </SecondaryContainer>
        </SecondaryContainer>
        <SecondaryContainer style={{ margin: "10px" }}>
          <GeneralButton href='/projects' variant='extended' color='primary'>
            more projects
          </GeneralButton>
        </SecondaryContainer>
      </SecondarySection>
    </>
  );
};

export default HomePage;
